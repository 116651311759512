<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/caracteristica-produto"
  >
    <FormField
      label="Nome"
      v-model="form.nome"
      :validation="$v.form.nome"
      @blur="onBlurName"
    />
    <FormField
      label="Nome interno"
      v-model="form.nome_interno"
      :validation="$v.form.nome_interno"
      v-stringscaped
    />
    <FormSwitch
      v-model="form.permite_alteracao_erp"
      label="Permitir que a integração desvincule produtos desta característica"
    />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { required } from 'vuelidate/lib/validators'
import FormSwitch from '@/components/form/FormSwitch'
import {
  CARACTERISTICA_PRODUTO_LOAD,
  CARACTERISTICA_PRODUTO_CREATE,
  CARACTERISTICA_PRODUTO_UPDATE
} from '@/store/actions/caracteristicaProduto'
import StringUtils from '../../../utils/stringUtils'
export default {
  name: 'caracteristicaProdutoForm',
  components: {
    SidePopup,
    FormField,
    FormButton,
    FormSwitch
  },
  validations: {
    form: {
      nome: { required },
      nome_interno: { required }
    }
  },
  data: () => ({
    mode: 'add',
    form: {
      nome: '',
      nome_interno: '',
      permite_alteracao_erp: true,
      isLoading: false
    }
  }),
  methods: {
    onBlurName() {
      if (!this.form.nome_interno) {
        this.form.nome_interno = StringUtils.normalizeUrl(this.form.nome)
      }
    },
    getData(id) {
      this.isLoading = true
      this.$store
        .dispatch(CARACTERISTICA_PRODUTO_LOAD, id)
        .then(resp => {
          this.isLoading = false
          this.form = resp
        })
        .catch(() => this.onClose(true))
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.isLoading = true
      if (this.mode == 'add') {
        this.$store
          .dispatch(CARACTERISTICA_PRODUTO_CREATE, this.form)
          .then(resp => {
            if (resp != undefined && resp.message) {
              this.$vueOnToast.pop('error', `${resp.message}`)
            }

            this.$vueOnToast.pop(
              'success',
              'Característica cadastrada com sucesso'
            )
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      } else {
        this.$store
          .dispatch(CARACTERISTICA_PRODUTO_UPDATE, this.form)
          .then(resp => {
            if (resp != undefined && resp.message) {
              this.$vueOnToast.pop('error', `${resp.message}`)
            }

            this.$vueOnToast.pop(
              'success',
              'Característica atualizado com sucesso'
            )
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      }
    },
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/caracteristica-produto')
      }
    }
  },
  computed: {
    title() {
      return this.mode == 'edit'
        ? 'Editar característica de produto'
        : 'Nova característica de produto'
    }
  },
  created() {
    let idRoute = this.$route.params.id
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    } else {
      this.editable = true
    }
  }
}
</script>
